$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_zindex';

$FADE_TIMEOUT: 225;

:export {
    FADE_TIMEOUT: $FADE_TIMEOUT;
}

.important.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    @include sassy-z-index(bump);
    background: linear-gradient(0deg, $sassy-color-overlay-light, $sassy-color-overlay-light),
        var(--poster, url(//));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: opacity #{$FADE_TIMEOUT}ms 0s cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    &.fade {
        opacity: 0;
    }
    svg {
        width: 40px;
    }
}
